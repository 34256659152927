@use '../utils/' as *;

.link-primary {
    color: $color-primary;
    text-decoration: none;
    font-weight: 700;

    &:hover {
        text-decoration: underline;
    }
}

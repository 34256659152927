@use './colors' as *;
@use './vars' as *;

h1,
h2,
h3,
h4 {
    font-weight: 700;
    margin: 0;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 22px;
    font-weight: 600;
    color: $color-primary;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 15px;
}

h5 {
    font-size: 14px;
}

p {
    line-height: $line-height-primary;
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
}
@use '../utils/' as *;

.badge {
    text-align: center;
    font-weight: 600;
    display: inline-block;
    padding: 8px 12px;
    margin-bottom: 8px;
    border-radius: $border-radius;

    &.primary {
        background: $color-secondary;
        color: $color-primary;
    }
}
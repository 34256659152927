@use 'index' as *;
@use 'utils' as *;

.container-button {
    margin-top: auto;
}

.qr-info {
    background: $color-secondary;
    color: $color-primary;
    padding: 16px;
    font-size: $font-small;
    border-radius: $border-radius;
    margin-bottom: 32px;

    & > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        & > li {
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0;
            }

            & span {
                font-weight: 600; 
            }
        }
    }
}
@use './components/' as *;
@use './utils/' as *;

* {
    box-sizing: border-box;
    outline: 0;
    letter-spacing: $letter-space;
}

body {
    background: url('/assets/images/background.jpg') no-repeat bottom right;
     background-color: #fff;
    background-size: cover;
    color: $color-body;
    font-family: $font;
    font-size: $font-regular;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Remove Chrome default input styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #f1f1f1 inset !important;
}

// Animate all link and button hovers
a,
button {
    transition: $animate-fast;
}

a {
    &:focus {
        outline: 0;
    }
}

// Main app container
.container {
    @include grid-height-padding;
    @include grid-primary;
    @include page-responsive;
}

//Step image
.step-img {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 16px;

  & > img {
    width: 100%;
    height: auto;

    &.step-01 {
      width: auto;
      max-height: 286px;
    }

    &.step-04 {
      width: auto;
      max-height: 247px;
    }

    &.step-05 {
      width: auto;
      max-height: 334px;
    }

  }
}

.logo-maxim {
    width: 142px;
    height: 24px;
    background: url('/assets/images/logo-maxim-bio@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.logo-clear-detect {
    width: 190x;
    height: 69px;
    background: url('/assets/images/logo-clear-detect@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
}
// video::cue {
//   background: none;
//   color: black;
// }  

// video::cue(b) {
//   color: black
// }

@use '../utils/' as *;

.btn {
    border: 0;
    border-radius: $border-radius;
    font-weight: 700;
    background: none;
    padding: 0 24px;
    font-family: $font;

    // Button styles
    &.primary {
        background: $color-primary;
        color: white;
    }

    &.secondary {
        background: $color-secondary;
        color: $color-primary;
    }

    &.with-icon {
        display: flex;
        align-items: center;
        color: $color-primary;
        
        & > img {
            margin-right: 8px;
        }

        &:hover {
            border: 2px solid;
        }
    }

    // Sizes
    &.regular {
        height: 48px;
        font-size: $font-regular;
        font-weight: 600;
        width: 100%;
    }

    // Disabled
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

// Font
$font: 'Inter', sans-serif;
$font-xsmall: 13px;
$font-small: 14px;
$font-regular: 16px;
$font-large: 18px;
$font-xlarge: 32px;
$letter-space: 0.3px;
$line-height-primary: 1.5;

// Layout
$page-padding: 24px;
$page-height: 100svh;

// Styling
$border-radius: 8px;

// Animate
$animate-fast: 0.15s ease;
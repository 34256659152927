@use 'index' as *;
@use 'utils' as *;

.container-button {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
}

video {
    width: 100%;
}
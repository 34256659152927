$screenSizes: (
    // Screen sizes
        xs: 476px,
        sm: 576px,
        md: 992px,
        lg: 1024px,
        xl: 1400px,
        xxl: 1920px
);

@mixin breakpoint($size, $invert: false) {
    $width: map-get($screenSizes, $size);

    @if $width == null {
        @error "Unknow screen size: #{$size}";
    }

    $prop: 'min-width';
    @if $invert {
        $prop: 'max-width';
        $width: $width - 1;
    }

    @media only screen and (#{$prop}: #{$width}) {
        @content;
    }
}

// 476px
@mixin small-mobile {
    @include breakpoint(xs, true) {
        @content;
    }
}

// 576px
@mixin mobile {
    @include breakpoint(sm, true) {
        @content;
    }
}

// 992px
@mixin desktop {
    @include breakpoint(md, true) {
        @content;
    }
}

// 1024px
@mixin tablet {
    @include breakpoint(lg, true) {
        @content;
    }
}

// 1400px
@mixin monitor {
    @include breakpoint(xl, true) {
        @content;
    }
}

// 1920px
@mixin large-monitor {
    @include breakpoint(xxl, true) {
        @content;
    }
}

// > 2000px
@mixin not-large-monitor {
    @include breakpoint(xxl) {
        @content;
    }
}

@use 'vars' as *;
@use 'breakpoints' as *;

@mixin grid-height-padding {
    height: $page-height;
    padding: $page-padding;
}

@mixin grid-primary {
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    gap: 32px;
}

@mixin grid-slider {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 32px;
}

@mixin ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin page-responsive {
    width: 1024px;
    margin: 0 auto;

    @include tablet {
        width: 100%;
        margin: 0;
    }
}